import { handleEncoding } from "./handleEncoding"
import { handleTitle } from "./handleTitle"
import { textCleanup } from "./textCleanup"

export const handleDescription = (text, title) => {
  if (!text || !text[0].length) return
  const encodedDescription = handleEncoding(text)
  const split = encodedDescription.split(".")
  let fixedText = encodedDescription
  if (split.length > 1) {
    fixedText = split.slice(0, -1)
    fixedText = fixedText.join(". \n")
  }

  let [description, specifications] = textCleanup(fixedText, handleTitle(title))

  if (
    description &&
    description.length &&
    description.trim().slice(-1) !== "."
  ) {
    description += "."
  }

  return [description, specifications]
}
