import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import Layout from "../components/Layout"
import { Button } from "semantic-ui-react"
import { device } from "../theme"
import { handleCharacters } from "../utils/handleCharacters"
import { handleDescription } from "../utils/handleDescription"
import SEO from "../components/SEO"
import { handleEncoding } from "../utils/handleEncoding"
import { JSXSchema } from "../components/JSXSchema"

const Container = styled.div`
  padding: 32px 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 980px;
  margin: 42px auto 0;

  @media ${device.tablet} {
    flex-direction: column;
    padding: 0;
    margin-bottom: 120px;
  }

  & .buy-link {
    background: ${props => props.theme.colors.green} !important;
    color: #fff !important;
    @media ${device.phone} {
      width: 100%;
      font-size: 24px;
      font-weight: bold;
    }
  }
`

const SKU = styled.span`
  display: block;
  color: #666;
  font-size: 12px;
  margin: 12px 0;
`

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  flex-basis: 30%;
  height: 380px;

  @media ${device.tablet} {
    flex-basis: 100%;
    height: 420px;
  }

  img {
    display: block;
    max-width: 100%;
    max-height: 100%;
    &:hover {
      cursor: -webkit-zoom-in;
      cursor: zoom-in;
    }
  }
`

const Brand = styled.label`
  color: #666;
`

const Content = styled.div`
  flex-basis: 50%;
  padding: 0 22px;

  @media ${device.tablet} {
    flex-basis: 100%;
    padding: 0;
    margin-top: 12px;
  }
`

const Title = styled.h1`
  text-transform: uppercase;
  font-size: 1.5rem;
  margin-bottom: 12px;
`

const Subtitle = styled.h2`
  text-transform: capitalize;
  font-size: 1.2rem;
  color: #888;
`

const Description = styled.p`
  font-size: 20px;
`

const PriceContainer = styled.div`
  margin: 12px 0;
  font-size: 20px;
`

const Price = styled.p`
  font-weight: bold;
  font-size: 1.2rem;
`

const OldPrice = styled.p`
  opacity: 0.7;
  font-size: 1rem;
  margin: 0;
  text-decoration: line-through;
`

const Breadcrumbs = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  margin-bottom: 22px;

  li {
    margin: 0 4px;
    text-transform: uppercase;
    font-size: 10px;
    a {
      color: gray;
    }
  }
`

function ProductPage({
  pageContext: {
    productTitle,
    productImage,
    productDescription,
    productPrice,
    productOldprice,
    productLink,
    productBrand,
    productPath,
    sku,
    categoryPage,
  },
}) {
  const isTop = categoryPage === "festtoppe"
  const title = isTop
    ? `Festtop fra ${productBrand} | ${handleCharacters(productTitle)}`
    : handleCharacters(productTitle)

  return (
    <>
      {categoryPage === "festtoppe" && <SEO title={title} />}
      <JSXSchema
        type="Product"
        name={productTitle}
        brand={productBrand}
        image={productImage}
        sku={sku}
        description={handleDescription(productDescription)}
        offers={{
          type: "Offer",
          price: productPrice,
          priceCurrency: "DKK",
          availability: "In stock",
        }}
        aggregateRating={{
          type: "AggregateRating",
          ratingValue: "4.8",
          reviewCount: "11",
        }}
      />
      <Layout
        title={title}
        description={`Læs mere omkring ${handleEncoding(
          title
        )} og se hvor du netop kan købe den`}
      >
        <JSXSchema
          type="BreadcrumbList"
          itemListElement={[
            {
              type: "ListItem",
              position: 1,
              item: { id: "https://www.lineout.dk/", name: "Forside" },
            },
            {
              type: "ListItem",
              position: 2,
              item: {
                id: `https://www.lineout.dk/${productPath}`,
                name: productPath,
              },
            },
            {
              type: "ListItem",
              position: 3,
              item: {
                id: `https://www.lineout.dk/${productPath}/${categoryPage}`,
                name: categoryPage,
              },
            },
          ]}
        />
        <Breadcrumbs>
          <li>
            <Link exact to="/">
              Forside
            </Link>
          </li>
          /
          <li>
            <Link exact to={`/${productPath}`}>
              {productPath}
            </Link>
          </li>
          /
          <li>
            <Link exact to={`/${productPath}/${categoryPage}`}>
              {categoryPage}
            </Link>
          </li>
          /<li>{productTitle}</li>
        </Breadcrumbs>
        <Container>
          <ImageContainer>
            <a href={productLink} target="_blank">
              <img src={productImage} alt={productTitle} />
            </a>
          </ImageContainer>
          <Content>
            {isTop ? (
              <>
                <Title>
                  {categoryPage} fra {productBrand}
                </Title>
                <Subtitle>{handleCharacters(productTitle)}</Subtitle>
              </>
            ) : (
              <>
                <Title>{handleCharacters(productTitle)}</Title>
                <Subtitle>
                  {categoryPage} fra {productBrand}
                </Subtitle>
              </>
            )}
            <Description>{handleDescription(productDescription)}</Description>
            <Brand>{handleCharacters(productBrand)}</Brand>
            <PriceContainer>
              {productOldprice &&
                Number(productOldprice) > Number(productPrice) && (
                  <OldPrice>{productOldprice}</OldPrice>
                )}
              <Price>{productPrice}</Price>
            </PriceContainer>
            <Button
              className="buy-link"
              as="a"
              href={productLink}
              target="_blank"
            >
              Gå til forhandler
            </Button>
            <SKU>Varenummer (SKU): {sku || new Date().getTime()}</SKU>
          </Content>
        </Container>
      </Layout>
    </>
  )
}

export default ProductPage
