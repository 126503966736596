import React from "react"

export const JSXSchema = props => {
  if (Object.keys(props).length === 0) return null

  const keys = {
    context: props.context || "https://schema.org/",
    ...props,
  }

  const reg = /"(context|type|id)":/g
  const schema = JSON.stringify(keys).replace(reg, `"@$1":`)

  return (
    <script
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: schema,
      }}
    />
  )
}
