export const textCleanup = (input, title) => {
    if (!input) return "";
  
    let description = input;
    description = description
      .replace("â", '"')
      .replace(/â/g, '"')
      .replace("God til:", "God til:\n")
      .replace("god til bl.", "god til blandt andet:");
  
    // Remove title if placed first in description
    const splitted = description.split(" ");
    description = splitted.filter((letter) => letter !== "\na.").join(" ");
  
    const splittedTitle = title.split(" ");
    const titleInText = splitted.slice(0, splittedTitle.length);
    if (splittedTitle.join(" ") === titleInText.join(" ")) {
      description = description.trim().replace(`${titleInText.join(" ")}`, "");
    }
  
    // Breakline to wine specifications
    let specifications = null;
    if (description.match("Vinens specifikation")) {
      let splitSpecs = description.split(/(Vinens specifikationer)/g);
      if (splitSpecs.length > 3) return;
      const [firstSplit] = splitSpecs;
  
      if (
        !firstSplit.toLowerCase().match("vinens specifikation") &&
        splitSpecs.length === 3
      ) {
        description = firstSplit;
        specifications = splitSpecs[1].concat(splitSpecs[2]);
      }
  
      if (
        firstSplit.toLowerCase().match("vinens specifikation") &&
        splitSpecs.length === 2
      ) {
        description = "";
        specifications = firstSplit.concat(splitSpecs[1]);
      }
    }
    return [description, specifications];
  };
  